/*
 * @Author: zhangyu
 * @Date: 2021-10-22 13:52:35
 * @LastEditTime: 2023-12-12 11:45:37
 */
// 接口字典
export default {
  // --------------新接口部分---------------

  //线上联调
  // 接入用户中心
  GET_LOGIN_USER_INFO: "bjdatascreen/user/loginUserInfo",
  // 退出登录
  NEW_LOGOUT: "bjdatascreen/sso_client/logout",
  SYN_USER: "bjdatascreen/user/synUser",

  // 组织架构列表
  NEW_JG_LIST: "bjdatascreen/screeningconditions/queryPermissionsParam",
  // 个人组织架构
  NEW_USER_JG_LIST: "bjdatascreen/dingDept/queryCurrentUser",

  // 人力专题数据条件筛选
  NEW_HUMAN_SELECT: "datascreen/dingUser/queryUserNum",
  // 人力成本列表
  NEW_COST_LIST: "datascreen/cost/queryAllByLimit",
  // 人力成本导入
  NEW_HUMAN_IMPORT: "datascreen/cost/batchImportCost",
  // 组织架构，产品线 list
  TRANSACTION_LIST: "bjdatascreen/screeningconditions/queryscreenParam",
  // 收入查询
  SELECT_TRANSACTION: "bjdatascreen/saleIncome/statistics",
  // 获取交易团队筛选条件
  SELECT_TEAM_MENU: "datascreen/salerConfig/teamQueryParam",
  // 交易分析查询
  SELECT_TEAM_STATISTICS: "datascreen/transactionStatistics/teamStatistics",
  //权限详情
  QUERY_BY_ID: "bjdatascreen/role/getById",
  // 新增用户
  NEW_SAVE_USER: "bjdatascreen/role/saveAndUpdate",
  //通过id删除权限
  DELETE_USER: "bjdatascreen/role/batchRemove",
  // 用户列表
  NEW_ROLE_LIST: "bjdatascreen/role/pageList",

  // 账号列表
  NEW_USER_LIST: "bjdatascreen/user/pageList",
  //查询用户
  USER_BYID: "bjdatascreen/user/queryById",
  //修改用户
  USER_UPDATE: "bjdatascreen/user/updateById",

  // 对比分析
  COMMONINCOME: "bjdatascreen/saleIncome/commonIncome ",

  //对比分析-组织架构
  TISSUE_COMPARISON: "bjdatascreen/saleIncome/tissueComparison",

  //收入模版下载
  SALEINCOME_DOWNLOAD: `bjdatascreen/saleIncome/download/`,

  //收入模版导入
  SALEINCOME_UPLOAD: "bjdatascreen/saleIncome/upload",

  //对比分析-产品线
  PRODUCTLINEINCOME: "bjdatascreen/saleIncome/productLineIncome",

  //对比分析-客户
  CUSTOMERGROUP: "bjdatascreen/saleIncome/customerGroup",

  //权限-客户
  HospitalOrDoctor: "bjdatascreen/regioncode/queryHospitalOrDoctor",

  // 首页——————————————————————————
  // 概况
  HOME_TRANSACTION: "bjdatascreen/home/statistics",

  //当月累计进度
  HOME_DEPTMONTHTOP: "bjdatascreen/home/deptMonthTop",

  //大区排名
  HOME_REGIONTOP: "bjdatascreen/home/regionTop",

  //地区排名
  HOME_DEPT4TOP: "bjdatascreen/home/dept4Top",

  // 成本
  FINANCE_COSTSUM: "datascreen/financeCost/financeCostSum", //成本概况
  FINANCE_DEPTCOST: "datascreen/financeCost/deptCost", //组织架构对比
  FINANCE_CUSTOMCOST: "datascreen/financeCost/areaCostTrend", //客户群体对比
  FINANCE_COSTTYPETREND: "datascreen/financeCost/costTypeTrend", //成本类型对比
  FINANCE_PLATFORMCOST: "datascreen/financeCost/platformCost", //平台流程对比
  FINANCE_PLATFORMLIST: "datascreen/financeCost/platformList", //平台流程列表
  FINANCE_PRODUCTLINECOST: "datascreen/financeCost/productLineCost", //产品线对比
  FINANCECOST_ORDERCOSTDETAILS: "financeCost/orderCostDetails", //订单分析

  //毛利
  SALEGROSSPROFIT_GROSSPROFITSUM: "datascreen/saleGrossProfit/grossProfitSum", //利润概况
  SALEGROSSPROFIT_DEPTCOST: "datascreen/saleGrossProfit/deptCost", //组织架构对比
  SALEGROSSPROFIT_AREACOSTTREND: "datascreen/saleGrossProfit/areaCostTrend", //地区
  SALEGROSSPROFIT_PRODUCTLINECOST: "datascreen/saleGrossProfit/productLineCost", //产品线对比

  //费用
  COST_GETALLCOSTTYPE: "datascreen/cost/getAllCostType", //费用类型筛选
  COST_GETCOSTCENTERITEMLIST: "datascreen/cost/getCostCenterItemList", //项目列表筛选
  COST_SUMMARY: "datascreen/cost/summary", //汇总

  COST_DOWNLOADCOSTTEMPLATE: "datascreen/cost/downloadCostTemplate/", //下载世纪费用模版
  COST_DOWNLOADBUDGETTEMPLATE: "datascreen/cost/downloadBudgetTemplate/", //下载预算费用模版
  COST_IMPORTBUDGETRECORD: "datascreen/cost/importBudgetRecord", //导入预算费用模版
  COST_IMPORTCOSTRECORD: "datascreen/cost/importCostRecord", //导入世纪费用模版

  COST_DEPARTCOMPARE: "datascreen/cost/departCompare", //组织架构对比
  COST_COSTTYPECOMPARE: "datascreen/cost/costTypeCompare", //费用类型
  COST_COSTCENTERITEMCOMPARE: "datascreen/cost/costCenterItemCompare", //项目费用对比

  //利润
  PROFIT_DEPARTCOMPARE: "datascreen/profit/departCompare", //利润概况
  PROFIT_SUMMARY: "datascreen/profit/summary", //组织架构对比

  //  三期初
  DEPT_LIST: "bjdatascreen/depttag/list", //权限管理-大区-列表
  DEPT_TAG_SAVE: "bjdatascreen/depttag/batch_save", //批量新增地区标签
  DEPT_TAG_DELETE: "bjdatascreen/depttag", //批量新增地区标签
  DEPT_TAG_EMPTY: "bjdatascreen/depttag/empty", //批量新增地区标签

  // 客户分析
  DOCTOR_ARCHIVES_LIST: "bjdatascreen/doctor_archives/list",
  DOCTOR_ARCHIVES_DROPDOW: "bjdatascreen/doctor_archives/dropdown_list", //人员产品xian
  COST_DETAILS: "bjdatascreen/salerdoctorcost/cost_details",
  DOCTORSALESVOLUMEANALYSIS_DOWNLOAD:
    "bjdatascreen/doctorSalesVolumeAnalysis/exportExcel", //导出费用明细
  CUSTOMERANALYSIS_DOWNLOAD: "bjdatascreen/customeranalysisdownload/page", //导出费用明细
  DOCTOR_EXPORT: "bjdatascreen/doctor_archives/export", //导出客户明细
  DOCTOR_EXPORT2: "bjdatascreen/doctor_archives/export2", //导出费用明细

  // 客户分析-公司
  // 医生维度统计销量
  CUSTOMERANALYSIS: "bjdatascreen/v3/customerAnalysis/statisticsByDoctor",
  // 导出医生维度统计销量
  CUSTOMERANALYSISEXPORT: "bjdatascreen/v3/customerAnalysis/export",
  // 查询授权组织架构
  CUSTOMERAUTHDEPTLIST: "bjdatascreen/v3/customerAnalysis/getAuthDeptList",
  // 根据大区查询关联的省市地区
  CUSTOMERPROVINCECITYAREA:
    "bjdatascreen/v3/customerAnalysis/getProvinceCityAreaList",
  // 根据行政区code查询医院
  CUSTOMERHOSPITALLIST: "bjdatascreen/v3/customerAnalysis/getHospitalList",
  // 查询队伍属性枚举
  CUSTOMERPERSONNEPERSONNELLLIST:
    "bjdatascreen/v3/customerAnalysis/getPersonnelIdentityList",

  // 新首页
  HOMEproductGroupList: "bjdatascreen/v3/home/productGroupList",
  HOMEhospitalList: "bjdatascreen/v3/home/hospitalList",
  HOMEauthDeptList: "bjdatascreen/v3/home/authDeptList",
  HOMEproductGroupSaleProgress: "bjdatascreen/v3/home/productGroupSaleProgress",
  DEPTSALEPROGRESS: "bjdatascreen/v3/home/deptSaleProgress", // 部门销售进度
  HOMEsaleAccess: "bjdatascreen/v3/home/saleAccess",
  HOMEsaleOaType: "bjdatascreen/v3/home/saleOaType",
  HOMEdAY: "bjdatascreen/v3/home/statistics/day", //业绩统计-日
  HOMEWEEK: "bjdatascreen/v3/home/statistics/week", //业绩统计-周
  HOMEMONTH: "bjdatascreen/v3/home/statistics/month", //业绩统计-月
  HOMEYEAR: "bjdatascreen/v3/home/statistics/year", //业绩统计-年
  HOMETARGETMONTH: "bjdatascreen/v3/home/targetAchieveProgress/month", // 目标达成进度-月
  HOMETARGETYEAR: "bjdatascreen/v3/home/targetAchieveProgress/year", // 目标达成进度-年
  HOMETARGETTIME: "bjdatascreen/v3/home/targetAchieveProgress/time", // 目标达成进度-序时
  HOMElargeAreaRanking: "bjdatascreen/v3/home/largeAreaRanking", // 所辖大区排名
  HOMEareaRanking: "bjdatascreen/v3/home/areaRanking", // 所辖地区排名
  HOMEAllareaRanking: "bjdatascreen/v3/home/areaRankingAll", // 全国地区排名
  HOMEAllLargeRanking: "bjdatascreen/v3/home/largeAreaRankingAll", // 全国大区排名

  // 产品组关联
  PRODUCTRELATIONLIST: "bjdatascreen/v3/productGroupRelation/list", //产品组列表
  PRODUCTRELATIONDetail: "bjdatascreen/v3/productGroupRelation/detail", //产品组详情
  PRODUCTRELATIONDELETE: "bjdatascreen/v3/productGroupRelation/delete", //产品组删除产品
  PRODUCTLIST: "bjdatascreen/v3/productGroupRelation/getProductList", //查询app所有产品列表
  PRODUCTADD: "bjdatascreen/v3/productGroupRelation/add", //产品组添加产品
  PRODUCTCLER: "bjdatascreen/v3/productGroupRelation/clear", //产品组清空产品
  PRODUCTEXPORT: "bjdatascreen/v3/productGroupRelation/export", //产品组导出
  PRODUCTEXPORTALL: "bjdatascreen/v3/productGroupRelation/exportAll", //产品组全量导出
  PRODUCTRELATIONIMPORT: "bjdatascreen/v3/productGroupRelation/import", //产品组导入

  // 地区关联
  REGIONlIST: "bjdatascreen/v3/doctorAreaRelation/list", //查询地区关联列表
  REGIONDELETE: "bjdatascreen/v3/doctorAreaRelation/delete", //删除地区关联
  REGIONEXPORT: "bjdatascreen/v3/doctorAreaRelation/export", //导出地区关联
  GETDEPTLIST: "bjdatascreen/v3/dept/getDeptList", //获取大区列表
  REGIONUPDATE: "bjdatascreen/v3/doctorAreaRelation/update", //修改地区关联
  REGIONIMPORT: "bjdatascreen/v3/doctorAreaRelation/import", //地区关联导入
  REGIONDCOTORLINKUPDATE:
    "bjdatascreen/v3/doctorAreaRelation/updateAllRelation", //全量更新医生关联地区数据

  // 大区关联
  DEPTLIST: "bjdatascreen/v3/largeAreaRelation/list", //修改地区关联
  DEPTDELETE: "bjdatascreen/v3/largeAreaRelation/delete", //删除地区关联
  GETPROVINCELIST: "bjdatascreen/v3/largeAreaRelation/getProvinceList", //删除地区关联
  GETCITYLIST: "bjdatascreen/v3/largeAreaRelation/getCityList", //根据省份查询城市列表
  DEPTADD: "bjdatascreen/v3/largeAreaRelation/add", //添加大区关联
  DEPTEXIST: "bjdatascreen/v3/largeAreaRelation/exist", //校验当前大区是否已存在
  DEPTDETAIL: "bjdatascreen/v3/largeAreaRelation/detail", //单个大区关联详情
  DEPTUPDATE: "bjdatascreen/v3/largeAreaRelation/update", //修改大区关联
  DEPTCLEAR: "bjdatascreen/v3/largeAreaRelation/clear", //清空大区关联
  DEPTEXPORT: "bjdatascreen/v3/largeAreaRelation/export", //清空大区关联
  DEPTIMPORT: "bjdatascreen/v3/largeAreaRelation/import", //清空大区关联

  // 数据导入
  DOWNLOADTEMPLATE:
    "bjdatascreen/v3/excelUpload/deptSaleTarget/downloadTemplate", //下载销售业绩营管口径指标导入模板
  IMPORTSALETARGET: "bjdatascreen/v3/excelUpload/deptSaleTarget/import", //导入销售业绩营管口径指标
  SALERDOWNLOADTEMPLATE:
    "bjdatascreen/v3/excelUpload/salerTarget/downloadTemplate", //下载销售指标导入模板
  SALERIMPORT: "bjdatascreen/v3/excelUpload/salerTarget/import", //下载销售指标导入模板
  // 数据导出
  EXPORTORDERINFO: "bjdatascreen/v3/home/exportOrderInfo", //导入销售业绩营管口径指标
};
